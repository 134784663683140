

























import { Component, Prop, Vue } from "vue-property-decorator";
import MField from "@/components/MField.vue";
import Field from "@/components/Field.vue";
import formatter from "@/mixins/formatter";
import ImageUploader from "vue-image-upload-resize";

@Component({
    components: { Field, MField, ImageUploader },
    mixins: [formatter],
})
export default class CompanyForm extends Vue {



    onSubmit() { }
}

